<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <SumHeaderTable
      :value="adGroups"
      :global-filter-fields="['adGroupName']"
      :columns="columns"
      :sums="sums"
      table-prefix="general"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import A2CAClient from '../../../../api/a2caAuthorizedClient';
import { ReportType, REPORT_DATE_FORMAT } from '../../../../constants/reportConstants';
import generateCommonColumns from '../../../../utils/tableUtils';
import { formatDefaultBid } from '../../../../utils/formatUtils';
import SumHeaderTable from '../../../SumHeaderTable.vue';
import { mergeApiAdGroupsWithReports } from '../../../../utils/reports/adGroupReportUtils';

export default {
  components: { SumHeaderTable },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
  },
  data() {
    const columns = [
      {
        id: 'adGroupName',
        field: 'adGroupName',
        header: 'Ad Group',
        sortable: true,
        numeric: false,
        decimal: false,
        visible: true,
        alwaysVisible: true,
        notInFilter: true,
        width: '300px',
        content: {
          center: false,
          to: (data) => ({ name: 'adGroupTargeting', params: { campaignId: this.campaignId, adGroupId: data.adGroupId } }),
          badge: null,
          format: null,
        },
      },
      {
        id: 'adGroupState',
        field: 'state',
        header: 'Status',
        sortable: true,
        numeric: false,
        decimal: false,
        visible: true,
        alwaysVisible: true,
        notInFilter: true,
        width: '100px',
        content: {
          center: false,
          to: null,
          badge: true,
          format: null,
        },
      },
      {
        id: 'defaultBid',
        field: 'defaultBid',
        header: 'Default Bid',
        sortable: true,
        numeric: false,
        decimal: false,
        alwaysVisible: true,
        notInFilter: true,
        width: '150px',
        content: {
          center: true,
          to: null,
          badge: null,
          remove: '$',
          format: (data) => formatDefaultBid(data, this.$store.state.auth.selectedProfile.currencyCode),
        },
      },
      ...generateCommonColumns(() => this.$store.state.auth.selectedProfile),
    ].map((column, index) => ({ ...column, order: index }));

    return {
      loading: false,
      adGroups: null,
      sums: null,
      columns,
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },
  watch: {
    dateRange(range) {
      if (range[1] !== null) {
        this.retrieveAdGroups();
      }
    },
    selectedProfile: {
      handler() {
        this.retrieveAdGroups();
      },
      immediate: true,
    },
  },
  methods: {
    retrieveAdGroups() {
      this.loading = true;
      this.adGroups = null;
      this.fetchAdGroups();
    },
    async fetchAdGroups() {
      const client = new A2CAClient(this.query);
      const reportsResponse = await client.getReports(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.AD_GROUPS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
        this.campaignId,
        ReportType.CAMPAIGNS,
        this.campaignId,
      );
      const retrievedReports = reportsResponse.parsedReport.reportData;

      const adGroups = await client.getAdGroups(this.selectedProfile.profileId,
        this.selectedProfile.region, this.campaignId);

      this.adGroups = mergeApiAdGroupsWithReports(retrievedReports, adGroups);
      this.sums = reportsResponse.aggregation;

      this.loading = false;
    },
  },
};
</script>
