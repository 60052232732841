import { ReportStatus } from '../../constants/reportConstants';
import { accumulateGroupedKpi, tryParseContent } from './reportUtils';

const buildIndividualAdGroupDailyReportsFromResponse = (reportsResponse) => {
  const reports = {};

  reportsResponse.filter((report) => report.state === ReportStatus.COMPLETE).forEach((report) => {
    reports[report.reportDate] = {
      data: tryParseContent(report.content),
    };
  });

  return reports;
};

const buildReportContentByCampaign = (reportsResponse) => {
  const reportsContent = [];

  reportsResponse.filter((report) => report.state === ReportStatus.COMPLETE).forEach((report) => {
    tryParseContent(report.content).forEach((entry) => reportsContent.push(entry));
  });
  return reportsContent;
};

const aggregateByAdGroup = (reportsContent, adGroups) => {
  const aggregatedAdGroups = reportsContent.reduce((acc, obj) => {
    const key = obj.adGroupId;
    const adGroup = adGroups.find((adgroup) => adgroup.adGroupId === obj.adGroupId);
    return accumulateGroupedKpi(acc, obj, key, adGroup);
  }, {});
  return aggregatedAdGroups;
};

const groupReportDataByAdGroup = (reportsContent) => {
  const groupedAdGroups = reportsContent.reduce((acc, obj) => {
    const key = obj.adGroupId;
    return accumulateGroupedKpi(acc, obj, key);
  }, {});
  return groupedAdGroups;
};

const mergeApiAdGroupsWithReports = (reportsContent, apiAdGroups) => {
  const mergedReportsContent = [];
  apiAdGroups.forEach((apiAdGroup) => {
    const reportContent = reportsContent.find((content) => content.adGroupId === apiAdGroup.adGroupId);
    if (reportContent) {
      reportContent.adGroupState = apiAdGroup.state;
      reportContent.adGroupName = apiAdGroup.name;
      reportContent.profileMetricsSetted = apiAdGroup.profleMetricsSetted;
      reportContent.state = apiAdGroup.state;
      reportContent.defaultBid = apiAdGroup.defaultBid;
      mergedReportsContent.push(reportContent);
    } else {
      mergedReportsContent.push({
        adGroupId: apiAdGroup.adGroupId,
        adGroupName: apiAdGroup.name,
        state: apiAdGroup.state,
        clicks: 0,
        cost: 0,
        impressions: 0,
        sales14d: 0,
        purchases14d: 0,
        kindleEditionNormalizedPagesRoyalties14d: 0,
        defaultBid: apiAdGroup.defaultBid,
      });
    }
  });

  return mergedReportsContent;
};

export {
  buildIndividualAdGroupDailyReportsFromResponse, buildReportContentByCampaign, aggregateByAdGroup, groupReportDataByAdGroup, mergeApiAdGroupsWithReports,
};
