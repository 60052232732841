<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <div
      class="
      pb-5"
    >
      <Card>
        <template #title>
          <h2>{{ campaignName }}</h2>
        </template>
        <template #content>
          <div class="text-right mb-3">
            <ReportsCalendar
              v-model="dateRange"
              report-type="CAMPAIGNS_AND_ADGROUPS"
            />
          </div>
          <GeneralChart
            :reports="reports"
            :kpi-aggregation="kpiAggregation"
          />
        </template>
      </Card>
    </div>
    <Card>
      <template #content>
        <h3>Ad Groups</h3>
        <AdGroupsTable
          :selected-profile="selectedProfile"
          :date-range="dateRange"
          :campaign-id="campaignId.toString()"
        />
      </template>
    </Card>
  </div>
  <Toast />
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import A2CAClient from '../../../../api/a2caAuthorizedClient';
import { ReportType, REPORT_DATE_FORMAT } from '../../../../constants/reportConstants';
import GeneralChart from '../../home/GeneralChart.vue';
import AdGroupsTable from './AdGroupsTable.vue';
import { getDateRange } from '../../../../utils/dateUtils';
import ReportsCalendar from '../../../ReportsCalendar.vue';

export default {
  components: { GeneralChart, AdGroupsTable, ReportsCalendar },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      reports: null,
      dateRange: getDateRange(!!this.query?.mockAccountId),
      kpiAggregation: null,
    };
  },
  computed: {
    campaignName() {
      return this.campaign.name;
    },
    campaignId() {
      return this.campaign.campaignId;
    },
    ...mapGetters(['royalties']),

  },
  watch: {
    dateRange(range) {
      if (range[1] !== null) {
        this.retrieveReports();
      }
    },
    selectedProfile: {
      handler() {
        this.retrieveReports();
      },
      immediate: true,
    },
  },

  methods: {
    retrieveReports() {
      this.loading = true;
      this.reports = null;
      this.fetchReports();
    },
    async fetchReports() {
      const client = new A2CAClient(this.query);
      const reportsRequest = client.getReports(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.CAMPAIGNS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
        this.campaignId,
        ReportType.CAMPAIGNS,
        this.campaignId,
      );

      const generalChartRequest = client.getGeneralChartData(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.CAMPAIGNS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
        this.campaignId,
        ReportType.CAMPAIGNS,
        this.campaignId,
      );

      const [reportsResponse, generalChartResponse] = await Promise.all([reportsRequest, generalChartRequest]);

      this.reports = generalChartResponse.dailyAggregations;
      this.kpiAggregation = reportsResponse.aggregation;
      this.loading = false;
    },
  },
};
</script>
